import React from 'react'
import './Contacts.scss'

const Contacts = () => {
    return (
        <footer className='contacts'>
          <p className='contacts__text'>info@melynojivakariene.lt</p>
        </footer>
    )
}

export default Contacts